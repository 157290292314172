import "./App.css";
import Hero from "./cmponents/Hero/Hero";
import Plans from "./cmponents/Plans/Plans";
import Programs from "./cmponents/Programs/Programs";
import Reasons from "./cmponents/Reasons/Reasons";
import Testimonials from "./cmponents/Testimonials/Testimonials";
import Join from "./cmponents/Join/Join";
import Footer from "./cmponents/Footer/Footer"

function App() {
  return (
    <div className="App">
      <Hero />
      <Programs />
      <Reasons />
      <Plans />
      <Testimonials />
      <Join />
      <Footer />

    </div>
  );
}

export default App;
