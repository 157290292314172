import React from 'react'
import "./Reasons.css";
import Tick from "../../assets/tick.png";
import Adidas from "../../assets/adidas.png";
import Nike from "../../assets/nike.png";
import NB from "../../assets/nb.png";
import Image1 from "../../assets/image1.png";
import Image2 from "../../assets/image2.png";
import Image3 from "../../assets/image3.png";
import Image4 from "../../assets/image4.png";

const Reasons = () => {
  return (
    <div className="reasons">
      <div className="reasons-left">
        <img src={Image1} alt="" />
        <img src={Image2} alt="" />
        <img src={Image3} alt="" />
        <img src={Image4} alt="" />
      </div>
      <div className="reasons-right">
        <span>some reasons</span>
        <div>
          <span className="stroke-text">why </span>
          <span>choose us?</span>
        </div>
        <div className="reasons-points">
          <img src={Tick} alt="" className="tick" />
          <span>over 140+ expert coaches</span>
        </div>
        <div className="reasons-points">
          <img src={Tick} alt="" className="tick" />
          <span>train smarter and faster than before</span>
        </div>
        <div className="reasons-points">
          <img src={Tick} alt="" className="tick" />
          <span>1 free program for new member</span>
        </div>
        <div className="reasons-points">
          <img src={Tick} alt="" className="tick" />
          <span>reliable partners</span>
        </div>
        <span>our partners</span>
        <div>
          <img src={NB} alt="" />
          <img src={Adidas} alt="" />
          <img src={Nike} alt="" />
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Reasons