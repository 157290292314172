import React from 'react';
import "./Footer.css";
import github from "../../assets/github.png";
import instagram from "../../assets/instagram.png";
import linkedin from "../../assets/linkedin.png";
import logo from "../../assets/logo.png"

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="upper">
        <img src={github} alt=""></img>
        <img src={instagram} alt=""></img>
        <img src={linkedin} alt=""></img>
      </div>
      <div className="lower">
        <img src={logo} alt=""></img>
      </div>
      <div className="blur footer-blur-l"></div>
      <div className="blur footer-blur-r"></div>
    </div>
  );
}

export default Footer;